import * as TreeModel from 'tree-model';
import * as Xlsx from 'xlsx';

const notificationTypes = {
  cs: '상담',
  memo: '댓글',
  board: '게시판',
  notice: '공지',
  salary: '급여',
};
export default {
  makeUserPermissionUpToDate(defaultPreset, targetPermission) {
    const resultPermission = { ...defaultPreset };
    Object.keys(resultPermission).forEach((category) => {
      Object.keys(resultPermission[category].menu).forEach((menu) => {
        if (
          targetPermission[category] !== undefined
          && targetPermission[category].menu[menu] !== undefined
          && targetPermission[category].menu[menu].bool === true
        ) {
          resultPermission[category].menu[menu].bool = true;
        }
        if (resultPermission[category].menu[menu].func !== undefined) {
          Object.keys(resultPermission[category].menu[menu].func).forEach((func) => {
            if (
              targetPermission[category] !== undefined
              && targetPermission[category].menu[menu] !== undefined
              && targetPermission[category].menu[menu].func[func] !== undefined
              && targetPermission[category].menu[menu].func[func].bool === true
            ) {
              resultPermission[category].menu[menu].func[func].bool = true;
            }
          });
        }
      });
    });
    return {
      ...resultPermission,
      boards: { ...targetPermission.boards },
    };
  },
  makeExcelFile(json, fileName) {
    const workBook = Xlsx.utils.book_new();
    const workSheet = Xlsx.utils.json_to_sheet(json);
    Xlsx.utils.book_append_sheet(workBook, workSheet, '목록');
    Xlsx.writeFile(workBook, fileName);
  },
  makeGroupListWithStaffs(groups, staffs, type) {
    const result = [
      {
        name: '전체',
        value: JSON.stringify({
          type: 'all',
          value: -1,
        }),
      },
    ];
    let groupList = groups;
    if (type === 'tree') groupList = this.makeGroupList(groups);
    const groupNullCnt = staffs.filter((staff) => staff.groupNo === null).length;
    if (groupNullCnt > 0) {
      groupList.push({
        name: '그룹없음',
        value: JSON.stringify({
          type: 'managerGroupNo',
          value: null,
        }),
      });
    }

    let allCnt = 0;
    groupList.forEach((r) => {
      const staffFilter = staffs.filter((staff) => staff.groupNo === r.value);
      if (staffFilter.length > 0) {
        const mapping = staffFilter.map((row) => ({
          name: row.name,
          value: JSON.stringify({
            type: 'manager',
            value: row.id,
          }),
        }));
        result.push(
          {
            name: `${r.name} (${staffFilter.length})`,
            value: JSON.stringify({
              type: 'managerGroupNo',
              value: r.value,
            }),
          },
          ...mapping,
        );
        allCnt += staffFilter.length;
      }
    });
    result[0].name = `전체 (${allCnt})`;
    return result;
  },
  getGroupFullPath(groups, groupNo, defaultName = '') {
    let returnName = defaultName;
    if (groups != null && groups.length > 0) {
      const tree = new TreeModel({
        childrenPropertyName: 'groups',
        modelComparatorFn: null,
      });
      if (groupNo !== null) {
        returnName = '';
        const root = tree.parse({ groups });
        const thisPath = root.first((node) => (node.model.no === groupNo)).getPath();
        for (let i = 1; i < thisPath.length; i += 1) {
          returnName += `${thisPath[i].model.name} `;
        }
      }
    }
    return returnName;
  },
  makeGroupList(groups, type = null) {
    const result = [];
    if (type === 'select') {
      result.push({ name: '전체', value: '전체' });
    }
    if (groups != null && groups.length > 0) {
      const root = new TreeModel({
        childrenPropertyName: 'groups',
        modelComparatorFn: null,
      }).parse({ groups });

      root.all((node) => {
        if (node.model.no !== undefined) {
          const thisPath = node.getPath();
          const value = node.model.no;
          const { head, deputyHead } = node.model;
          let name = '';
          for (let i = 1; i < thisPath.length; i += 1) {
            name += `${thisPath[i].model.name} `;
          }
          result.push({
            name,
            value,
            head,
            deputyHead,
          });
        }
      });
    }
    return result;
  },
  getGroupChildrens(groups, groupNo) {
    const result = [groupNo];
    if (groups != null && groups.length > 0) {
      const tree = new TreeModel({
        childrenPropertyName: 'groups',
        modelComparatorFn: null,
      });
      if (groupNo !== null) {
        const root = tree.parse({ groups });
        root.all((r) => {
          if (r.model.no !== undefined) {
            const map = r.getPath().map((node) => node.model.no);
            map.forEach((no, i) => {
              if (no === groupNo && i < map.length - 1) {
                result.push(...map.slice(i + 1, map.length));
              }
            });
          }
        });
      }
    }
    return [...new Set(result)];
  },
  transNotifictionType(type) {
    return notificationTypes[type];
  },
  priceToString(price) {
    try {
      const priceString = price === null ? '0' : price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return `${priceString} 원`;
    } catch (error) {
      return '0 원';
    }
  },
  makePhoneNumber(str) {
    try {
      const phone = str.replaceAll('-', '');
      return `${phone.substring(0, 3)}-${phone.substring(3, 7)}-${phone.substring(7, str.length + 1)}`;
    } catch (error) {
      return '';
    }
  },
  makePrivatePhoneNumber(str) {
    try {
      const phone = str.replaceAll('-', '');
      return `${phone.substring(0, 3)}-****-${phone.substring(7, str.length + 1)}`;
    } catch (error) {
      return '';
    }
  },
};
